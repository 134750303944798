import React from 'react';
import { locationData } from './locationData';

const LocationInfo = ({ location }) => {
  const data = locationData[location];

  if (!data) {
    return null;
  }

  const renderText = text => {
    const paragraphs = text.split('\n\n');
    return paragraphs.map((paragraph, pIndex) => {
      const parts = paragraph.split(/(\[.*?\]\(.*?\))/);
      return (
        <p key={pIndex}>
          {parts.map((part, index) => {
            const match = part.match(/\[(.*?)\]\((.*?)\)/);
            if (match) {
              const [, linkText, linkKey] = match;
              const href = data.links[linkKey];
              return (
                <a key={index} href={href}>
                  {linkText}
                </a>
              );
            }
            return part;
          })}
        </p>
      );
    });
  };

  return (
    <div>
      <h2>{data.title}</h2>
      {renderText(data.text)}
    </div>
  );
};

export default LocationInfo;
