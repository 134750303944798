import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { bool, shape, string, object, arrayOf } from 'prop-types';
import styled from 'styled-components';
import { Page, LayoutComposer } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { propTypes } from '../../util/types';
import { locationData } from './locationData';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarSearchFormLocation from '../../components/Topbar/TopbarSearchForm/TopbarSearchFormLocation';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import HowItWorks from './HowItWorks';
import FAQ from './Faq';
import LocationListings from './LocationListings';
import Sublocations from './Sublocations';
import ListingReviews from './ListingReviews';
import LocationInfo from './LocationInfo';

import css from './SeoGlendale.module.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  padding: 2rem;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1200px) {
    max-width: 1000px;
  }

  @media (max-width: 1000px) {
    max-width: 800px;
  }

  @media (max-width: 800px) {
    max-width: 600px;
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

const Header = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  line-height: 1.6;

  @media (max-width: 600px) {
    font-size: 2rem;
  }
`;

const Subheading = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

const Button = styled.button`
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin: 1rem 0;

  @media (max-width: 600px) {
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  font-size: 1rem;

  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

function formatLocationName(cityName) {
  return cityName
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const SubLocationPageComponent = props => {
  const {
    listings,
    reviews,
    sublocations,
    isLoading,
    error,
    sublocationNotFound,
    params,
    intl,
    scrollingDisabled,
  } = props;

  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const { location, sublocation, category } = params;
  const locationFormatted = formatLocationName(location);
  const sublocationFormatted = formatLocationName(sublocation);

  const handleSubmit = values => {
    const { search, selectedPlace } = values?.location;
    const { origin, bounds } = selectedPlace;
    const searchParams = { ...(origin && { origin }), address: search, bounds };
    const url = createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams);
    window.location.href = url;
  };
  if (sublocationNotFound || category !== 'cakes') return <NotFoundPage />;

  const data = locationData[sublocationFormatted];
  const title =
    sublocationFormatted === 'Pasadena'
      ? 'Pasadena Cakes'
      : intl.formatMessage(
          { id: 'SubLocationPage.title' },
          { sublocation: sublocationFormatted, location: locationFormatted }
        );
  const description =
    sublocationFormatted === 'Pasadena'
      ? 'Discover Pasadena cakes from local bakers near you. Order now for as low as $30.'
      : intl.formatMessage(
          { id: 'SubLocationPage.description' },
          { sublocation: sublocationFormatted, location: locationFormatted }
        );

  return (
    <Page
      className={css.root}
      title={title}
      description={description}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'LocationShops',
        description: description,
        name: title,
      }}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutComposer
        areas={`
          topbar
          main
          footer
        `}
        className={css.layout}
      >
        {({ Topbar, Main, Footer }) => (
          <>
            <Topbar as="header" className={css.topbar}>
              <TopbarContainer />
            </Topbar>
            <Main className={css.mainwrapper}>
              <UnifiedView
                location={locationFormatted}
                sublocation={sublocationFormatted}
                handleSubmit={handleSubmit}
                config={config}
                listings={listings}
                reviews={reviews}
                sublocations={sublocations}
                category={category}
                intl={intl}
              />
            </Main>
            <Footer>
              <FooterContainer />
            </Footer>
          </>
        )}
      </LayoutComposer>
    </Page>
  );
};

const UnifiedView = ({
  location,
  sublocation,
  handleSubmit,
  config,
  listings,
  reviews,
  sublocations,
  category,
  intl,
}) => (
  <Container>
    <div style={{ textAlign: 'center' }}>
      <Text>World's largest marketplace for local bakers/bakeries</Text>
      <Header>{sublocation} Cakes</Header>
      <Text>Discover Thousands of Delectable Desserts from Local Bakers in {sublocation}</Text>

      <div>
        <TopbarSearchFormLocation
          className={css.searchLink}
          desktopInputRoot={css.topbarSearchWithLeftPadding}
          onSubmit={handleSubmit}
          appConfig={config}
          isMobile={false}
          locationcity={`${sublocation}`}
          sublocation={sublocation}
        />
        <Button>Order {category} nearby</Button>
      </div>
      <TextContainer>
        <Text>100% Guarantee</Text>
        <Text>Local Delivery</Text>
      </TextContainer>
    </div>
    <LocationListings
      sublocation={sublocation}
      location={location}
      notitle={true}
      latestListings={listings}
    />
    <HowItWorks />
    <ListingReviews reviews={reviews} />
    <LocationInfo location={sublocation} />
    <FAQ location={sublocation} />
    <Sublocations
      sublocations={sublocations}
      location={location}
      category={category}
      sublocation={sublocation}
    />
  </Container>
);

SubLocationPageComponent.defaultProps = {
  listings: [],
  reviews: [],
  sublocations: [],
  isLoading: false,
  error: null,
  sublocationNotFound: false,
};

SubLocationPageComponent.propTypes = {
  params: shape({
    location: string.isRequired,
    sublocation: string.isRequired,
    category: string.isRequired,
  }).isRequired,
  listings: arrayOf(propTypes.listing),
  reviews: arrayOf(propTypes.review),
  sublocations: arrayOf(string),
  isLoading: bool,
  error: propTypes.error,
  sublocationNotFound: bool,
  intl: intlShape.isRequired,
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => ({
  listings: state.SubLocationPage.listings,
  reviews: state.SubLocationPage.reviews,
  sublocations: state.SubLocationPage.sublocations,
  isLoading: state.SubLocationPage.isLoading,
  error: state.SubLocationPage.error,
  sublocationNotFound: state.SubLocationPage.sublocationNotFound,
  scrollingDisabled: isScrollingDisabled(state),
});

const SubLocationPage = compose(connect(mapStateToProps), injectIntl)(SubLocationPageComponent);

export default SubLocationPage;
